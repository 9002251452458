import {useState, useEffect} from "react";
import WithDialog, {DialogBodyProps} from "components/DialogSkeleton/WithDialog";
import {Grid, makeStyles, Theme, createStyles} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import JalaliUtils from "@date-io/jalaali";
import jMoment from "moment-jalaali";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import qs from "qs";
import {useHistory, useLocation} from "react-router-dom";

jMoment.loadPersian({dialect: "persian-modern", usePersianDigits: true});
export interface FilterShippersProps {
  onConfirm: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRed: {
      backgroundColor: "#fb3640 !important",
      fontFamily: "Vazir",
      height: "3rem",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: 20,
    },
    buttonGreen: {
      backgroundColor: "#1eae98 !important",
      fontFamily: "Vazir",
      height: "3rem",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: 20,
      marginTop: 50,
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "column",
      gap: "0.875rem",
    },
  })
);
function FilterShippersModal({onConfirm: handleConfirm, onClose}: DialogBodyProps<FilterShippersProps>) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [create_Date, handleCreateDate] = useState<moment.Moment | null>(null);
  const [source, setSource] = useState<string>("");
  const [status, setStatus] = useState("");
  const [strategy, setStrategy] = useState("");
  useEffect(() => {
    const {
      createDate = undefined,
      userSource = undefined,
      accountStatus = undefined,
      userStrategy = undefined,
    } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (createDate) {
      handleCreateDate(moment(createDate as string));
    }
    if (userSource) {
      setSource(userSource as string);
    }
    if (userStrategy) {
      setStrategy(userStrategy as string);
    }
    if (accountStatus) {
      setStatus(accountStatus as string);
    }
  }, [location.search]);
  return (
    <Grid container direction='column' className='gap-1' xs={12} style={{width: "30rem"}}>
      <Grid item container xs={12} className='flex flex-row justify-around'>
        <div>
          <h3 className='font-bold mb-2'>تاریخ ثبت نام : </h3>
          <MuiPickersUtilsProvider utils={JalaliUtils} locale='fa'>
            <DatePicker
              clearable
              okLabel='تأیید'
              cancelLabel='لغو'
              clearLabel='پاک کردن'
              labelFunc={(date) => (date ? date.format("jYYYY/jMM/jDD") : "")}
              value={create_Date ? create_Date : null}
              onChange={(date) => date && handleCreateDate(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Grid>
      <Grid item container xs={12} className='flex flex-row justify-around pt-10'>
        <div>
          <h3 className='font-bold mb-2'>منبع : </h3>
          <ButtonGroup color='primary' aria-label='outlined primary button group'>
            <Button
              variant={source === "garage" ? "contained" : "outlined"}
              style={{width: 92}}
              onClick={() => {
                setSource("garage");
              }}
            >
              گاراژ
            </Button>
            <Button
              variant={source === "other" ? "contained" : "outlined"}
              style={{width: 92}}
              onClick={() => {
                setSource("other");
              }}
            >
              سایر
            </Button>
          </ButtonGroup>
        </div>
        <div>
          <h3 className='font-bold mb-2'>وضعیت حساب : </h3>
          <ButtonGroup color='primary' aria-label='outlined primary button group'>
            <Button
              variant={status === "active" ? "contained" : "outlined"}
              style={{width: 92}}
              onClick={() => {
                setStatus("active");
              }}
            >
              فعال
            </Button>
            <Button
              variant={status === "inActive" ? "contained" : "outlined"}
              style={{width: 92}}
              onClick={() => {
                setStatus("inActive");
              }}
            >
              غیرفعال
            </Button>
          </ButtonGroup>
        </div>
      </Grid>
      <Grid item container xs={12} className='flex flex-row pt-10'>
        <div className='w-full'>
          <h3 className='font-bold mb-2'>استراتژی انتشار بار : </h3>
          <ButtonGroup color='primary' aria-label='outlined primary button group' className='w-full'>
            <Button
              variant={strategy === "direct" ? "contained" : "outlined"}
              className='w-1/3'
              onClick={() => {
                setStrategy("direct");
              }}
            >
              مستقیم
            </Button>
            <Button
              variant={strategy === "afterEnter" ? "contained" : "outlined"}
              className='w-1/3'
              onClick={() => {
                setStrategy("afterEnter");
              }}
            >
              پس از تایید
            </Button>
            <Button
              variant={strategy === "reject" ? "contained" : "outlined"}
              className='w-1/3'
              onClick={() => {
                setStrategy("reject");
              }}
            >
              عدم انتشار
            </Button>
          </ButtonGroup>
        </div>
      </Grid>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.buttonGreen}
          fullWidth
          onClick={() => {
            const newParams = qs.stringify(
              {
                createDate: create_Date ? create_Date.locale("en").format("YYYY-MM-DD") : undefined,
                userSource: source ? source : undefined,
                userStrategy: strategy ? strategy : undefined,
                accountStatus: status ? status : undefined,
              },
              {addQueryPrefix: true}
            );
            history.replace(`/dashboard/shippers/list/${newParams}`);

            onClose?.({}, "backdropClick");
          }}
        >
          اعمال فیلترها
        </Button>
        <Button
          className={classes.buttonRed}
          fullWidth
          onClick={() => {
            history.replace(`/dashboard/shippers/list`);
            onClose?.({}, "backdropClick");
          }}
        >
          حذف فیلترها
        </Button>
      </div>
    </Grid>
  );
}

export default WithDialog(FilterShippersModal, {title: "فیلتر های بیشتر"}, "filterShippers");
