import {makeStyles} from "@material-ui/core/styles";
import BaseLayout from "components/BaseLayout";
import _404 from "pages/_404";
import React, {lazy} from "react";
import {Route, RouteComponentProps, RouteProps, Switch} from "react-router-dom";
import {TPermissions} from "services/constants/role/model";

interface IRouteInstance {
  /**
   * React component to render for the path, or `false` if no component should be rendered.
   *
   * If `false` is passed, then 404 will be shown.
   * @see TRoute.notFoundComponent
   */
  component: React.FC<any> | false;
  /**
   * Name of the page to be displayed in breadcrumb. May be a static string, or a callback with current route as param
   * returning a string.
   *
   * The callback is useful with dynamic paths.
   *
   * The path can be excluded from breadcrumb hierarchy, if `false` is passed.
   */
  name: string | ((param: string) => string) | false;
  /**
   * List of sub-routes to be rendered descendant this path. Avoid using `/` on defined paths.
   *
   * Note that any static path should become before dynamic paths, or the static path will not match at all.
   */
  subRoutes?: Record<string, IRouteInstance>;
  /**
   * Custom name to be rendered, despite hierarchy.
   *
   * Can be a static string, or a callback with total path as string array as param returning string.
   *
   * Note that this name doesn't participate in preceding hierarchy, so descendent paths will use the `name` as usual.
   */
  crumbCustomName?: string | ((params: string[]) => string);
  /**
   * Layout to be rendered for this path. The layout should be an {ElementType} accepting children and breadcrumb.
   * Can be disabled by passing `false`. If absent, the `defaultLayout` will be rendered (if present) instead.
   *
   * @see TRoute.defaultLayout
   */
  layout?:
    | React.ElementType<{
        crumb: JSX.Element;
        children: React.PropsWithChildren<any>["children"];
        [key: string]: any;
      }>
    | false;
  /**
   * Permissions for this route. Routes that have `[]` as permissions will be private routes.
   *
   * Notice that passing `[]` is not necessary because it means, __the user that are not logged in would not see the
   * page__. User's that aren't logged in will be redirected to `/dashboard/auth` automatically by axios interceptor
   * before routes get rendered.
   */
  permissions?: TPermissions[];
}

type TRoute = {
  /**
   * Routes tree.
   *
   * Should be started with `/` (base path).
   */
  routes: {
    "/": IRouteInstance;
  };
  /**
   * The default layout for all routes (unless they specify their layout explicitly or turn it off).
   *
   * @see IRouteInstance.layout
   */
  defaultLayout?: React.ElementType<{
    crumb: JSX.Element;
    children: React.PropsWithChildren<any>["children"];
    [key: string]: any;
  }>;
  /**
   * Separator used for separating crumb names.
   */
  crumbSeparator: string;
  /**
   * Component to render, if the path is not present in the tree, or if the path is disabled explicitly.
   *
   * @see IRouteInstance.component
   */
  notFoundComponent: React.ElementType;
};

// prettier-ignore
const routesInstance: TRoute = {
  crumbSeparator: " - ",
  defaultLayout: BaseLayout,
  notFoundComponent: _404,
  routes: {
    "/": {
      component: lazy(() => import("pages")),
      name: false,
      layout: false,
      subRoutes: {
        "dashboard": {
          name: false,
          component: lazy(() => import("pages/dashboard")),
          layout: false,
          subRoutes:{
            "auth": {
            component: lazy(() => import("pages/dashboard/auth")),
            name: false,
            layout: false,
          }, 
          "drivers": {
            component: lazy(() => import("pages/dashboard/drivers")),
            name: false,
            permissions: [],
            subRoutes: {
              "reports": {
                component: lazy(() => import("pages/dashboard/drivers/reports")),
                name:false,
                crumbCustomName: "لیست انتقادات و پیشنهادات",
              },
              "list": {
                component: lazy(() => import("pages/dashboard/drivers/list")),
                name:false,
                crumbCustomName: "لیست رانندگان",
              },
              ":id": {
                component: lazy(() => import("pages/dashboard/drivers/[id]")),
                name: (param) => param,
                crumbCustomName: "راننده",
              },
            },
          },
          "shippers": {
            component: lazy(() => import("pages/dashboard/shippers")),
            name: false,
            permissions: [],
            subRoutes: {
              "list": {
                component: lazy(() => import("pages/dashboard/shippers/list")),
                name:false,
                crumbCustomName: "لیست اعلام بارکنندگان",
              },
              ":id": {
                component: lazy(() => import("pages/dashboard/shippers/[id]")),
                name: (param) => param,
                crumbCustomName: "اعلام بارکننده",
              },
            },
          },
          "loads": {
            component: lazy(() => import("pages/dashboard/loads")),
            name: false,
            permissions: [],
            subRoutes: {
              "list": {
                component: lazy(() => import("pages/dashboard/loads/list")),
                name:false,
                crumbCustomName: "لیست بارها",
              },
              ":id": {
                component: lazy(() => import("pages/dashboard/loads/[id]")),
                name: (param) => param,
                crumbCustomName: "جزئیات بار",
              },
            },
          },
          "management": {
            component: lazy(() => import("pages/dashboard/management")),
            name: false,
            permissions: [],
            subRoutes: {
              "smsList": {
                component: lazy(() => import("pages/dashboard/management/smsList")),
                name:false,
                crumbCustomName: "لیست درخواست های ورود با پیامک",
              },
              "reportsList": {
                component: lazy(() => import("pages/dashboard/management/reportsList")),
                name:false,
                crumbCustomName: "لیست گزارشات",
              },
            },
          },
        }
        },
      },
    },
  },
};

/**
 * Renders the route tree.
 *
 * @example
 * <Route path="/">
 *   <Switch>
 *     <Route path="/" exact />
 *     <Route path="/sub-route-1">
 *       <Switch>
 *         <Route path="/sub-route-1" exact />
 *         <Route path="/sub-route-1/leaf-node" exact />
 *       </Switch>
 *     </Route>
 *   </Switch>
 * </Route>
 * @param tree {IRouteInstance} the root of tree
 * @param names {(string | ((param: string) => string) | false)[]} the crumb names so far
 * @param basePath {string} the path of current route
 * @param parentPermission {TPermissions[] | undefined} the permissions so far
 */
const renderRoute = (
  {component: C, crumbCustomName, layout: L, name, permissions, subRoutes}: IRouteInstance,
  names: (string | ((param: string) => string) | false)[],
  basePath: string = "",
  parentPermission: TPermissions[] | undefined = undefined
) => {
  // permissions of this route and its ancestors
  const totalPermission =
    parentPermission || permissions ? [...(parentPermission || []), ...(permissions || [])] : undefined;
  const Layout = L === false ? undefined : L || routesInstance.defaultLayout;
  const Component = C || routesInstance.notFoundComponent;

  /**
   * calculates crumb names and custom crumb name for route
   * @param pathname
   */
  const calculateCrumbNames = ({location: {pathname}}: RouteComponentProps) => {
    const lastPath = pathname.split("/")[names.length];
    const crumbNames = names
      .filter((name) => name !== false)
      .map((value) => (typeof value === "function" ? value(lastPath) : value)) as string[];
    if (name !== false) {
      if (typeof name === "function") {
        crumbNames.push(name(lastPath));
      } else {
        crumbNames.push(name);
      }
    }
    const customName =
      typeof crumbCustomName === "function"
        ? crumbCustomName(pathname.split("/").map((value, index) => (index === 0 ? "/" : value)))
        : crumbCustomName;
    return [crumbNames, customName] as const;
  };
  if (!!subRoutes && !!Object.keys(subRoutes).length) {
    // if this route has sub-routes:
    return (
      <Route
        key={`outerRoute-${basePath}`}
        path={basePath || "/"}
        component={undefined}
        render={(props) => {
          const lastRoutePath = props.location.pathname.split("/")[names.length];
          return (
            <Switch key={`switch-${basePath}`}>
              {totalPermission ? (
                <PrivateRoute
                  permissions={totalPermission}
                  path={basePath || "/"}
                  key={`innerRoute-${basePath}`}
                  exact
                  component={undefined}
                  render={(props) => {
                    const [crumbNames, customName] = calculateCrumbNames(props);
                    return Layout ? (
                      <Layout
                        crumb={
                          <BreadCrumbs
                            separator={routesInstance.crumbSeparator}
                            crumbs={customName ? [customName] : crumbNames}
                          />
                        }
                      >
                        <Component {...props} />
                      </Layout>
                    ) : (
                      <Component {...props} />
                    );
                  }}
                />
              ) : (
                <Route
                  path={basePath || "/"}
                  exact
                  component={undefined}
                  key={`innerRoute-${basePath}`}
                  render={(props) => {
                    const [crumbNames, customName] = calculateCrumbNames(props);
                    return Layout ? (
                      <Layout
                        crumb={
                          <BreadCrumbs
                            separator={routesInstance.crumbSeparator}
                            crumbs={customName ? [customName] : crumbNames}
                          />
                        }
                      >
                        <Component {...props} />
                      </Layout>
                    ) : (
                      <Component {...props} />
                    );
                  }}
                />
              )}
              {Object.entries(subRoutes).map(([key, value]) =>
                renderRoute(
                  value,
                  [...names, typeof name === "function" ? name(lastRoutePath) : name],
                  `${basePath}/${key}`,
                  totalPermission
                )
              )}
              <Route component={_404} />
            </Switch>
          );
        }}
      />
    );
  } else {
    // no sub-routes (leaf node)
    return totalPermission ? (
      <PrivateRoute
        permissions={totalPermission}
        path={basePath || "/"}
        component={undefined}
        key={`leafRoute-${basePath}`}
        exact
        render={(props) => {
          const [crumbNames, customName] = calculateCrumbNames(props);
          return Layout ? (
            <Layout
              crumb={
                <BreadCrumbs
                  separator={routesInstance.crumbSeparator}
                  crumbs={customName ? [customName] : crumbNames}
                />
              }
            >
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          );
        }}
      />
    ) : (
      <Route
        exact
        path={basePath || "/"}
        component={undefined}
        key={`leafRoute-${basePath}`}
        render={(props) => {
          const [crumbNames, customName] = calculateCrumbNames(props);
          return Layout ? (
            <Layout
              crumb={
                <BreadCrumbs
                  separator={routesInstance.crumbSeparator}
                  crumbs={customName ? [customName] : crumbNames}
                />
              }
            >
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          );
        }}
      />
    );
  }
};

/**
 * @author SedAli
 * @param permissions
 * @param render
 * @param rest
 * @constructor
 */
const PrivateRoute = ({permissions, render, ...rest}: RouteProps & {permissions: TPermissions[]}) => {
  /**
   * redirect path is set to / not /dashboard/auth and / path will redirect automatically to /dashboard/profile.
   * because private routes in this project is only for
   * users that already logged in. and logged in user always has profile page. so it's better to redirect them
   * to /profile page not /auth.
   * the users that arent logged in. and try to access the application pages will redirect to /dashboard/auth
   * by axios interceptor. because they do not have valid tokens.
   */
  // const notEmptyToken = !!LSService.getAccessToken() || !!LSService.getRefreshToken();
  //   const notEmptyToken = Cookie.get("token");
  //return needs tobe change
  //   if (!notEmptyToken) {
  //     return (
  //       <Redirect
  //         to={{
  //           pathname: "/dashboard/auth",
  //         }}
  //       />
  //     );
  //   }
  // console.log(user, "user");
  // console.log(isInitial, "is initial");

  return (
    <Route
      {...rest}
      render={(props) => {
        // return !isInitial ? null : user.isAuth && canRender ? render?.(props) : <Unauthorized />;
        return render?.(props);
      }}
    />
  );
};

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "1.625rem",
    fontWeight: 900,
  },
}));

/**
 *
 * @param crumbs {string[]} the crumbs to render
 * @param separator
 * @constructor
 */
function BreadCrumbs({crumbs, separator}: {crumbs: string[]; separator: string}) {
  const classes = useStyles();
  return (
    <div className='w-full flex justify-start items-center'>
      {crumbs.map((breadcrumb, index) =>
        breadcrumb ? (
          <React.Fragment key={`${breadcrumb}${index.toString()}`}>
            <h1 className={classes.title} key={breadcrumb}>
              {breadcrumb}
            </h1>
            {index !== crumbs.length - 1 && <span className={classes.title}>{separator}</span>}
          </React.Fragment>
        ) : (
          ""
        )
      )}
    </div>
  );
}

export const routesRendered = renderRoute(
  routesInstance.routes["/"],
  [],
  undefined,
  routesInstance.routes["/"].permissions
);
