import clsx from "clsx";
import {Dialog, DialogProps, Grow, IconButton, Typography, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rootContainerPaper: ({mobileSize}: {mobileSize: boolean}) => ({
    padding: "1.875rem",
    left: mobileSize ? "-50%" : "",
  }),
  dialog: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0.5rem",
      },
      "*::-webkit-scrollbar-track": {
        margin: "2.5rem 0",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#fdca40",
        borderRadius: "20px",
      },
    },
  },
  dialogTitle: {
    marginBottom: "2.5rem",
  },
  divider: {
    top: "7.625rem",
  },
  closeIcon: {
    left: "1.875rem",
  },
  title: {
    fontSize: "1.5rem",
    lineHeight: "normal",
    fontWeight: "bold",
    fontFamily: "Vazir",
  },
  image: {
    height: "6.25rem",
    marginBottom: "1.25rem",
  },
}));

export interface DialogSkeletonSpecificProps {
  title: string;
  hasCloseButton?: boolean;
  image?: string;
  imageClassName?: string;
}

export type DialogSkeletonProps = DialogProps & DialogSkeletonSpecificProps;

export type DialogSkeletonDefaultProps = Pick<
  DialogSkeletonProps,
  | "title"
  | "style"
  | "className"
  | "children"
  | "disableBackdropClick"
  | "disableEscapeKeyDown"
  | "fullScreen"
  | "fullWidth"
  | "hasCloseButton"
  | "hideBackdrop"
  | "id"
  | "image"
  | "keepMounted"
  | "onClose"
  | "open"
  | "imageClassName"
>;

export type CommonDialogSkeletonProps = Pick<DialogSkeletonDefaultProps, "open" | "onClose">;

export function DefaultDialogSkeleton(props: DialogSkeletonDefaultProps) {
  return <DialogSkeleton {...props} />;
}

export default function DialogSkeleton({
  title,
  hasCloseButton,
  onClose: handleClose,
  children,
  image,
  imageClassName,
  ...props
}: DialogSkeletonProps) {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({mobileSize});
  return (
    <Dialog
      {...props}
      onClose={handleClose}
      TransitionComponent={Grow}
      transitionDuration={400}
      maxWidth={false}
      classes={{
        paper: `bg-white ${classes.rootContainerPaper} rounded-10px relative flex flex-col items-center`,
      }}
      PaperProps={{elevation: 0}}
      className={classes.dialog}
    >
      {hasCloseButton && (
        <IconButton
          onClick={(event) => handleClose?.(event, "escapeKeyDown")}
          className={`${classes.closeIcon} absolute top-2r h-6 w-6 bg-background-default p-0`}
        >
          <img alt='close-dialog' src='close' />
        </IconButton>
      )}
      {!!image && <img alt='asset' src={image} className={clsx([classes.image, imageClassName || ""])} />}
      <div id='dialog-title' className={`${classes.dialogTitle} inline-flex justify-center`}>
        <Typography color='textPrimary' align='center' className={classes.title}>
          {title}
        </Typography>
      </div>
      <div id='dialog-body' className='w-full'>
        {children}
      </div>
    </Dialog>
  );
}
