import {MuiThemeProvider} from "@material-ui/core";
import {StylesProvider} from "@material-ui/core/styles";
import React from "react";
import {theme} from "services/constants";
import RTLProvider from "./RTLProvider";
import {ModalProvider} from "services/contexts/ModalContext/Provider";
import Modals from "./Modals";

const Providers: React.FC = ({children}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <RTLProvider>
          <ModalProvider>
            <Modals />
            {children}
          </ModalProvider>
        </RTLProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
};

export default Providers;
