import {useModalDispatch, useModalState} from "services/contexts/ModalContext";
import {modalsPayload} from "services/contexts/ModalContext/types";
import DialogSkeleton, {CommonDialogSkeletonProps, DialogSkeletonProps} from ".";
import React from "react";

export type DialogBodyProps<T> = T & CommonDialogSkeletonProps;

export default function WithDialog(
  DialogBody: React.ElementType,
  dialogCommonProps: Omit<DialogSkeletonProps, "open" | "onClose">,
  name: keyof modalsPayload
) {
  const Component = () => {
    const openModal = useModalState();
    const handleCloseModal = useModalDispatch(null);
    const open = openModal?.modal === name;
    const handleClose = () => handleCloseModal();
    const payload = openModal?.payload || {};
    return (
      <DialogSkeleton {...dialogCommonProps} {...payload} open={open} onClose={handleClose}>
        <DialogBody {...dialogCommonProps} {...payload} open={open} onClose={handleClose} />
      </DialogSkeleton>
    );
  };
  return () => Component();
}
