import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import BaseLayout from "components/BaseLayout";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    marginTop: "15vh",
    width: "17rem",
    paddingRight: "1.8rem",
  },
  number: {
    fontSize: "3rem",
    color: theme.palette.secondary.main,
    marginTop: "2rem",
  },
  text: {
    fontSize: "1.5rem",
    fontWeight: 900,
    color: theme.palette.text.primary,
    marginTop: "1rem",
  },
}));

const _404: React.FC<any> = () => {
  const styles = useStyle();
  return (
    <BaseLayout crumb={<div />}>
      <div className={styles.container}>
        <p className={styles.number}>۴۰۴</p>
        <p className={styles.text}>صفحه مورد نظر یافت نشد</p>
      </div>
    </BaseLayout>
  );
};

export default _404;
