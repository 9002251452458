import React from "react";
import clsx from "clsx";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SettingsIcon from "@material-ui/icons/Settings";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {images} from "../services/constants/images";
import {useHistory, useLocation} from "react-router-dom";
import {redirect} from "services/utils/redirect";

interface IProps {
  crumb: JSX.Element;
}
interface DataItems {
  icon: JSX.Element;
  name: string;
  route: string;
  children?: Partial<DataItems>[];
}
const data: DataItems[] = [
  {
    icon: <LocalShippingIcon color='secondary' />,
    name: "رانندگان",
    route: "/dashboard/drivers",
    children: [
      {name: "لیست رانندگان", route: "/dashboard/drivers/list"},
      {name: "لیست انتقادات و پیشنهادات", route: "/dashboard/drivers/reports"},
    ],
  },
  {
    icon: <PersonIcon color='secondary' />,
    name: "اعلام بار کنندگان",
    route: "/dashboard/shippers",
    children: [{name: "لیست اعلام بارکنندگان", route: "/dashboard/shippers/list"}],
  },
  {
    icon: <img src={images.icons.box} alt='box' style={{paddingRight: 3}} />,
    name: "بارها",
    route: "/dashboard/loads",
    children: [{name: "لیست بارها", route: "/dashboard/loads/list"}],
  },
  {
    icon: <SettingsIcon color='secondary' />,
    name: "مدیریتی",
    route: "/dashboard/management",
    children: [
      {name: "لیست درخواست های ورود با پیامک", route: "/dashboard/management/smsList"},
      {name: "لیست گزارشات", route: "/dashboard/management/reportsList"},
      {name: "فعال کردن smart look", route: "/dashboard/management/smartLook"},
    ],
  },
];
const BaseLayout: React.FC<IProps> = ({children, crumb}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);
  const history = useHistory();
  const {pathname} = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            {crumb.props.crumbs?.[0]}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <img src={images.assets.icon} alt='icon' className='h-11' />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {data.map(({icon, name, route, children}) => (
            <>
              <ListItem
                button
                key={name}
                onClick={() => {
                  !children?.length && history.push(route);
                  if (selectedItem === name) {
                    setSelectedItem(null);
                  } else {
                    setSelectedItem(name);
                  }
                }}
                style={{
                  backgroundColor: pathname === route && !children?.length ? theme.palette.primary.dark : "transparent",
                }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} style={{color: theme.palette.secondary.main}} />
                {selectedItem && children?.length && selectedItem === name ? (
                  <ExpandLess />
                ) : selectedItem && children?.length && selectedItem !== name ? (
                  <ExpandMore />
                ) : !selectedItem && children?.length ? (
                  <ExpandMore />
                ) : null}
              </ListItem>
              {children?.length && (
                <Collapse in={selectedItem === name} timeout='auto'>
                  <List component='div' disablePadding>
                    {children.map((child) => (
                      <ListItem
                        button
                        className={classes.nested}
                        style={{
                          backgroundColor: pathname === child.route ? theme.palette.primary.dark : "transparent",
                        }}
                        onClick={() => {
                          history.push(child.route!);
                        }}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon classes={{root: "w-5"}} color='secondary' />
                        </ListItemIcon>
                        <ListItemText
                          classes={{primary: "text-xs"}}
                          primary={child.name}
                          style={{color: theme.palette.secondary.main}}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </>
          ))}
        </List>
        <ListItem
          button
          onClick={() => {
            redirect("/dashboard/auth");
          }}
          style={{marginTop: "auto", marginBottom: 50}}
        >
          <ListItemIcon>
            <ExitToAppIcon color='secondary' />
          </ListItemIcon>
          <ListItemText primary='خروج' style={{color: theme.palette.secondary.main}} />
        </ListItem>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.light,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export default BaseLayout;
