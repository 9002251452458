import {useState, useEffect} from "react";
import WithDialog, {DialogBodyProps} from "components/DialogSkeleton/WithDialog";
import {Grid, makeStyles, Theme, createStyles} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import JalaliUtils from "@date-io/jalaali";
import jMoment from "moment-jalaali";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import qs from "qs";
import {useHistory, useLocation} from "react-router-dom";

jMoment.loadPersian({dialect: "persian-modern", usePersianDigits: true});
export interface FilterDriversProps {
  onConfirm: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRed: {
      backgroundColor: "#fb3640 !important",
      fontFamily: "Vazir",
      height: "3rem",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: 20,
    },
    buttonGreen: {
      backgroundColor: "#1eae98 !important",
      fontFamily: "Vazir",
      height: "3rem",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: 20,
      marginTop: 50,
    },
  })
);
function FilterDriversModal({onConfirm: handleConfirm, onClose}: DialogBodyProps<FilterDriversProps>) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [create_Date, handleCreateDate] = useState<moment.Moment | null>(null);
  const [update_Date, handleUpdateDate] = useState<moment.Moment | null>(null);
  const [truck, setTruck] = useState("");
  const [loader, setLoader] = useState("");
  const [version, setVersion] = useState("");
  const [status, setStatus] = useState("");
  const trucks = ["کامیون 1", "کامیون 2", "کامیون 3"];
  const loaders = ["جفت 1", "جفت 2", "جفت 3"];
  const versions = ["0.7.7", "0.7.8", "0.7.9"];
  const handleTruckChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setTruck(event.target.value as string);
  };
  const handleLoaderChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setLoader(event.target.value as string);
  };
  const handleVersionChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setVersion(event.target.value as string);
  };
  useEffect(() => {
    const {
      createDate = undefined,
      updateDate = undefined,
      truckName = undefined,
      loaderName = undefined,
      versionCode = undefined,
      accountStatus = undefined,
    } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (createDate) {
      handleCreateDate(moment(createDate as string));
    }
    if (updateDate) {
      handleUpdateDate(moment(updateDate as string));
    }
    if (truckName) {
      setTruck(truckName as string);
    }
    if (loaderName) {
      setLoader(loaderName as string);
    }
    if (versionCode) {
      setVersion(versionCode as string);
    }
    if (accountStatus) {
      setStatus(accountStatus as string);
    }
  }, [location.search]);
  return (
    <Grid container direction='column' className='gap-1' xs={12} style={{width: "30rem"}}>
      <Grid item container xs={12} className='flex flex-row justify-around'>
        <div>
          <h3 className='font-bold mb-2'>تاریخ ثبت نام : </h3>
          <MuiPickersUtilsProvider utils={JalaliUtils} locale='fa'>
            <DatePicker
              clearable
              okLabel='تأیید'
              cancelLabel='لغو'
              clearLabel='پاک کردن'
              labelFunc={(date) => (date ? date.format("jYYYY/jMM/jDD") : "")}
              value={create_Date ? create_Date : null}
              onChange={(date) => date && handleCreateDate(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <h3 className='font-bold mb-2'>تاریخ آخرین به روز رسانی : </h3>
          <MuiPickersUtilsProvider utils={JalaliUtils} locale='fa'>
            <DatePicker
              clearable
              okLabel='تأیید'
              cancelLabel='لغو'
              clearLabel='پاک کردن'
              labelFunc={(date) => (date ? date.format("jYYYY/jMM/jDD") : "")}
              value={update_Date ? update_Date : null}
              onChange={(date) => date && handleUpdateDate(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Grid>
      <Grid item container xs={12} className='flex flex-row justify-around pt-10'>
        <div>
          <h3 className='font-bold mb-2'>نوع ناوگان : </h3>
          <FormControl style={{minWidth: 185}}>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={truck}
              onChange={handleTruckChange}
              fullWidth
            >
              {trucks.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <h3 className='font-bold mb-2'>نوع بارگیر : </h3>
          <FormControl style={{minWidth: 185}}>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={loader}
              onChange={handleLoaderChange}
              fullWidth
            >
              {loaders.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item container xs={12} className='flex flex-row justify-around pt-10'>
        <div>
          <h3 className='font-bold mb-2'>وضعیت حساب : </h3>
          <ButtonGroup color='primary' aria-label='outlined primary button group'>
            <Button
              variant={status === "active" ? "contained" : "outlined"}
              style={{width: 92}}
              onClick={() => {
                setStatus("active");
              }}
            >
              فعال
            </Button>
            <Button
              variant={status === "inActive" ? "contained" : "outlined"}
              style={{width: 92}}
              onClick={() => {
                setStatus("inActive");
              }}
            >
              غیر فعال
            </Button>
          </ButtonGroup>
        </div>
        <div>
          <h3 className='font-bold mb-2'>نسخه : </h3>
          <FormControl style={{minWidth: 185}}>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={version}
              onChange={handleVersionChange}
              fullWidth
            >
              {versions.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <div className='flex flex-col gap-3.5'>
        <Button
          className={classes.buttonGreen}
          fullWidth
          onClick={() => {
            const newParams = qs.stringify(
              {
                createDate: create_Date ? create_Date.locale("en").format("YYYY-MM-DD") : undefined,
                updateDate: update_Date ? update_Date.locale("en").format("YYYY-MM-DD") : undefined,
                truckName: truck ? truck : undefined,
                loaderName: loader ? loader : undefined,
                versionCode: version ? version : undefined,
                accountStatus: status ? status : undefined,
              },
              {addQueryPrefix: true}
            );
            history.replace(`/dashboard/drivers/list/${newParams}`);

            onClose?.({}, "backdropClick");
          }}
        >
          اعمال فیلترها
        </Button>
        <Button
          className={classes.buttonRed}
          fullWidth
          onClick={() => {
            history.replace(`/dashboard/drivers/list`);
            onClose?.({}, "backdropClick");
          }}
        >
          حذف فیلترها
        </Button>
      </div>
    </Grid>
  );
}

export default WithDialog(FilterDriversModal, {title: "فیلتر های بیشتر"}, "filterDrivers");
