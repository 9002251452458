import {createBrowserHistory} from "history";

export const browserHistory = createBrowserHistory();
export const redirect = (path: string, replace = false, state: string | undefined = undefined) => {
  if (replace) {
    browserHistory.replace(path);
  } else {
    browserHistory.push(path, state);
  }
};
