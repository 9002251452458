// @ts-ignore
const {createMuiTheme} = require("@material-ui/core");

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "#137551",
      main: "#29a179",
      light: "#90EE90",
      disabled: "#fee4ca",
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
    },
    secondary: {
      main: "#00416d",
      light: "#003457",
    },
    success: {
      light: "#ebf5f1",
      main: "#08a435",
    },
    info: {
      main: "#d6fffa",
    },
    grey: {
      "50": "#D6D6D6",
      "100": "#D8D8D8",
      "200": "#515151",
      "300": "#4A4A4A",
      "500": "#EFEFEF",
      "600": "#7B7B7B",
      "700": "#F2F2F2",
    },
  },
  typography: {
    fontFamily: "Vazir",
    fontWeightMedium: 500,
    fontWeightRegular: "normal",
    fontWeightBold: "bold",
  },
  direction: "rtl",
  overrides: {
    MuiDialog: {
      root: {
        direction: "ltr",
      },
    },
    MuiStepIcon: {
      root: {
        "&$completed": {
          color: "#4A4A4A",
        },
        "&$active": {
          color: "#4A4A4A",
        },
      },
      text: {
        fill: "#fff",
      },
    },
    MuiButton: {
      root: {
        "&.animated": {
          "& .MuiButton-label": {
            zIndex: 110,
            position: "relative",
          },
          "&:hover": {
            transform: "translateY(-3px)",
            boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.2)",
          },
          "&:after": {
            content: '""',
            display: "inline-block",
            height: "100%",
            width: "100%",
            borderRadius: "inherit",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
            transition: "all .6s",
            background: "inherit",
            opacity: "1 !important",
          },
          "&:hover:after": {
            transform: "scaleX(1.4) scaleY(1.6)",
            opacity: "0 !important",
          },
          "&:focus": {
            transform: "translateY(-1px)",
            boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
  },
});

module.exports = {theme};
