import WithDialog, {DialogBodyProps} from "components/DialogSkeleton/WithDialog";
import {Grid, Theme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles, createStyles} from "@material-ui/styles";
export interface IAcceptConfirmModalProps {
  onConfirm: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRed: {
      backgroundColor: "#fb3640 !important",
      fontFamily: "Vazir",
      height: "3rem",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: 20,
    },
    buttonGreen: {
      backgroundColor: "#1eae98 !important",
      fontFamily: "Vazir",
      height: "3rem",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: 20,
      marginTop: 50,
    },
  })
);

const AcceptConfirmModal = ({onConfirm: handleConfirm, onClose}: DialogBodyProps<IAcceptConfirmModalProps>) => {
  const classes = useStyles();
  return (
    <Grid container direction='column' className='gap-3.5' style={{width: "30rem"}}>
      <Grid item>
        <Button
          className={classes.buttonGreen}
          fullWidth
          onClick={() => {
            handleConfirm();
            onClose?.({}, "escapeKeyDown");
          }}
        >
          بله، تایید
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.buttonRed}
          fullWidth
          onClick={() => {
            onClose?.({}, "escapeKeyDown");
          }}
        >
          خیر، انصراف
        </Button>
      </Grid>
    </Grid>
  );
};

export default WithDialog(
  AcceptConfirmModal,
  {title: "آیا از تغییر وضعیت حساب مطمئن هستید ؟", hasCloseButton: false},
  "acceptConfirm"
);
