import FilterDrivers from "./FilterDrivers";
import FilterShippers from "./FilterShippers";
import ConfirmModal from "./ConfirmModal";

export default function Modals() {
  return (
    <>
      <FilterDrivers />
      <FilterShippers />
      <ConfirmModal />
    </>
  );
}
