import {imagesProxy} from "../../utils/proxies/imagesProxy";

const imagesBasePath = "/images/";

const imagesInnerPaths = {
  assets: "assets",
  base: "",
  icons: "icons",
  mock: "mock",
};

const base = {};

const assets = {
  icon: "icon.png",
};

const icons = {
  arrowUpDark: "arrow-up-dark.svg",
  arrowDownDark: "arrow-down-dark.svg",
  checkPurple: "check-purple.svg",
  box: "box.svg",
};

const mock = {
  maleUser1: "36.jpg",
  maleUser2: "79.jpg",
  femaleUser1: "60.jpg",
  femaleUser2: "65.jpg",
};

const allImages = {
  assets,
  base,
  icons,
  mock,
};

export const images = imagesProxy(imagesBasePath, allImages, imagesInnerPaths);
